@import "../../../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.herobanner-wrapper {
    margin: 0;
    position: relative;
}

.herobanner-img {
    position: relative;
    width: 100%;

    img {
        height: auto;
        width: 100%;
    }

    &.mobile-img-caption {
        @include media-breakpoint-down(md) {
            height: 100%;

            img {
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

.herobanner-block {
    padding: $space-24;
    position: relative;

    .hero-heading {
        font-size: $font-29;
        line-height: 120%;

        * {
            color: inherit;
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            margin: 0;
        }
    }

    &.block-desktop {
        @include media-breakpoint-up(lg) {
            height: 100%;
            position: absolute;
            z-index: 2;

            &-left {
                left: 0;
                right: auto;
            }

            &-right {
                left: auto;
                right: 0;
            }

            &-center {
                left: 0;
                margin: 0 auto;
                right: 0;
            }

            &-caption {
                bottom: 0;
                height: auto;
                top: auto;
            }

            &-after {
                height: auto;
                position: relative;
            }
        }
    }

    &.block-mobile {
        @include media-breakpoint-down(md) {
            &-caption {
                bottom: 0;
                position: absolute;
            }

            &-after {
                position: relative;
            }
        }

        @include media-breakpoint-up(lg) {
            &-caption {
                top: 0;
            }
        }
    }

    &.style {
        &-hero-banner {
            font-size: $font-18;
            padding-bottom: $space-64;

            @include media-breakpoint-up(lg) {
                padding: $space-48 $space-48 $space-48 $space-88;
            }

            &.block-desktop-right {
                @include media-breakpoint-up(lg) {
                    padding: $space-48 $space-88 $space-48 $space-48;
                }
            }

            p {
                font-size: inherit;
            }

            .hero-heading {
                font-family: $font-questa;
                font-size: $font-46;
                font-weight: $font-semi-bold;
                margin-bottom: $space-8;
            }

            .hero-anchors {
                margin-top: $space-32;
            }
        }

        &-hero-block {
            font-size: $font-18;

            @include media-breakpoint-up(lg) {
                padding: $space-48 $space-88;
            }

            p {
                font-size: inherit;
            }

            .hero-heading {
                font-family: $font-questa;
                font-size: $font-36;
                font-weight: $font-black;
                margin-bottom: $space-4;
            }

            &.block-desktop-caption,
            &.block-desktop-after {
                @include media-breakpoint-up(lg) {
                    padding: $space-16 $space-32 $space-32 $space-32;
                }
            }

            .hero-anchors {
                margin-top: $space-24;
            }
        }

        &-highlight-block {

            @include media-breakpoint-up(lg) {
                padding: $space-16 $space-32 $space-32 $space-32;
            }

            p {
                font-size: $font-16;
            }

            .hero-heading {
                font-family: $font-sofia;
                font-size: $font-26;
                margin-bottom: $space-8;
            }


            .hero-anchors {
                margin-top: $space-16;
            }
        }
    }
}

.content-block-background {
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.content-block-wrapper {
    position: relative;
    z-index: 2;
}

.round-corners {
    border-radius: $border-radius-8;
    overflow: hidden;
}

.bread-financial-plcc {
    background-color: $color-sa300;
    border: 0;
    border-radius: $border-radius-4;
    color: $color-t300;
    cursor: pointer;
    display: block;
    font-family: "sofia-pro", sans-serif;
    font-size: $font-14;
    font-weight: $font-bold;
    letter-spacing: 0.05rem;
    line-height: 1rem;
    padding: $space-16 $space-24;
    text-transform: uppercase;
    white-space: nowrap;
    width: 100%;

    .ep-text-placement {
        align-items: center;
        cursor: pointer;
        display: flex;
        justify-content: center;
    }
}
